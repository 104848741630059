import { gql } from '@apollo/client';

export const FETCH_MORE_DOCUMENTS_QUERY = gql`
  query FETCH_MORE_DOCUMENTS_QUERY($module: ItemId, $categories: [ItemId], $skip: IntType) {
    documents: allDocuments(
      filter: {
        documentCategory: { allIn: $categories }
        module: { eq: $module }
        isFeatured: { eq: "false" }
      }
      orderBy: name_ASC
      first: "100"
      skip: $skip
    ) {
      id
      name
      documentCategory {
        name
      }
      module {
        slug
      }
      price
      priceCode
      fileUrl
      freeDocument
      isFeatured
      hasPassword
    }
  }
`;

export const FETCH_FEATURED_DOCUMENTS_QUERY = gql`
  query FETCH_FEATURED_DOCUMENTS_QUERY($module: ItemId, $categories: [ItemId]) {
    featuredDocumentsCount: _allDocumentsMeta(
      filter: {
        documentCategory: { allIn: $categories }
        module: { eq: $module }
        isFeatured: { eq: "true" }
      }
    ) {
      count
    }
    featuredDocuments: allDocuments(
      filter: {
        documentCategory: { allIn: $categories }
        module: { eq: $module }
        isFeatured: { eq: "true" }
      }
      orderBy: name_ASC
      first: "100"
    ) {
      id
      name
      documentCategory {
        name
      }
      price
      priceCode
      fileUrl
      freeDocument
      isFeatured
    }
  }
`;
